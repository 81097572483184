<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values , index) in errors_back" :key="index">
          <li v-for="(value , valIndex) in values" :key="valIndex">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <b-row class="row-eq-height">
      <!-- User Info: Left col -->
      <b-col md="6" class="d-flex justify-content-between flex-column ">
        <!-- User Avatar & Action Buttons -->

        <b-card :title="$t('Order_Show.Notes')" class="h-100">
          <label for="textarea-default">{{ $t("Order_Show.Note") }}</label>
          <b-form-textarea
            id="textarea-default"
            v-model="loggerNote.note"
            :placeholder="$t('Order_Show.Note')"
            rows="3"
          />
          <b-button variant="primary" class="mt-1" @click="storeLogger(id)">{{
            $t("Order_Show.Submit")
          }}</b-button>
        </b-card>
      </b-col>
      <b-col class="mb-2" md="6">
        <b-card class="h-100">
          <div class="d-flex justify-content-center">
            <!-- update Status -->
            <b-button
              block
              v-b-modal.modal-cancel
              v-if="payoutData.valid_to_change_status"
              variant="danger "
              class=" mr-1 mt-1"
            >
              {{ $t("Order_Show.Cancel") }}
            </b-button>
            <b-modal
              id="modal-cancel"
              cancel-variant="outline-secondary"
              @ok="cancelData"
              ok-title="Ok"
              cancel-title="Close"
              centered
              title="Cancel"
            >
              <b-form
                style="max-height:500px; overflow-x:hidden; overflow-y:auto"
              >
                <label for="textarea-default">{{
                  $t("Order_Show.Note")
                }}</label>
                <b-form-textarea
                  id="textarea-default"
                  v-model="cancel.note"
                  placeholder="Note"
                  rows="3"
                />
              </b-form>
            </b-modal>
          </div>
          <hr v-if="payoutData.valid_to_change_status" />
          <div class=" d-flex justify-content-around text-center">
            <div class="">
              <h6 class="text-muted font-weight-bolder">
                {{ $t("Order_Show.Created_at") }}
              </h6>
              <p class="mb-0">
                {{ payoutData.created_at }}
              </p>
            </div>
            <div class="">
              <h6 class="text-muted font-weight-bolder">
                {{ $t("Order_Show.Updated_at") }}
              </h6>
              <p class="mb-0">
                {{ payoutData.updated_at }}
              </p>
            </div>
            <div class="">
              <h6 class="text-muted font-weight-bolder">
                {{ $t("Order_Show.Status") }}
              </h6>
              <p class="mb-0">
                {{ payoutData.status }}
              </p>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col md="6" class="d-flex justify-content-between flex-column ">
        <!-- User Avatar & Action Buttons -->

        <b-card title="الطلبات" class="h-100">
          <p class="mb-2 h2">الاجمالى: {{ payoutData.price }} جنيه</p>
          <b-row>
            <b-col
              cols="12"
              v-for="(item, index) in payoutData.orders"
              :key="index"
            >
              <p class="mb-2 h3">
                الكود: <span class="text-primary"> {{ item.code }}</span> -
                :المبلغ المستحق
                <span class="text-primary"> {{ item.payout_total }} جنية</span>
                | التاريخ:
                <span class="text-primary"> {{ item.created_at }}</span>
              </p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-card title="Files" v-if="payoutData.file != null">
      <table id="table">
        <tr>
          <th>Link</th>
        </tr>
        <tr>
          <td>{{ payoutData.file }}</td>
        </tr>
      </table>
    </b-card>
  </div>
</template>

<script>
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

export default {
 
  components: {
    AppTimeline,
    AppTimelineItem,
    CoolLightBox,
  },
  data() {
    return {
      per: null,
      id: 0,
      errors_back: [],
      showDismissibleAlert: false,
      payoutData: {},
      loggerNote: {
        note: "",
      },
      imgIndex: null,
      profileArr: [],
      vendor: {},

      cancel: {
        type: "manual",
        note: "",
        status: "cancel",
      },
    };
  },
  created() {
    // fetch data of value
    this.id = this.$route.params.id;
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios
        .get("payouts/" + this.id)
        .then((result) => {
          this.payoutData = result.data.data;
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    storeLogger(id) {
      axios
        .post("payouts/" + id + "/logger/store", this.loggerNote)
        .then((result) => {
          this.$swal({
            position: "center",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
          });
          this.fetchData();
        })
        .catch((err) => {
          this.$swal({
            position: "center",
            icon: "error",
            title: "Error!",
            showConfirmButton: false,
            timer: 1500,
          });
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    cancelData() {
      axios
        .put("payouts/" + this.payoutData.id + "/update", this.cancel)
        .then((result) => {
          this.$swal({
            position: "center",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
          });
          this.fetchData();
        })
        .catch((err) => {
          this.$swal({
            position: "center",
            icon: "error",
            title: "Error!",
            showConfirmButton: false,
            timer: 1500,
          });
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    // delete translation function
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>
